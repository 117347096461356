<template>
  <div>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-card-text class="ma-0 pa-0 fill-height" style="min-height: 100px">
        <template v-for="(screen, index) in screensFiltered">
          <v-card flat tile :key="'screenCard' + index">
            <v-img v-if="screen.image" :src="imageBase + screen.image" />
            <v-card-text v-if="screen.content && screen.content.trim()">
              <Markdown :value="screen.content"></Markdown>
            </v-card-text>
          </v-card>
          <v-divider :key="'screenDivider' + index" />
        </template>
      </v-card-text>
    </v-card>
    <v-overlay absolute :value="overlay" @click="overlay = false" :z-index="2">
      <v-alert
        :prominent="!$vuetify.breakpoint.xs"
        type="error"
        dense
        border="left"
        class="mx-4 mt-6"
      >
        {{
          display.holiday
            ? "Während den Ferien wird die Anzeigetafel nicht bedient"
            : "Die Anzeigetafel ist momentan ausgeschaltet"
        }}
      </v-alert>
    </v-overlay>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Screen",
  data() {
    return {
      loading: false,
      display: {},
      overlay: false,
      screens: [],
    };
  },
  computed: {
    imageBase() {
      if (this.isProd()) {
        return "https://api.gymkirchenfeld.ch/screen/";
      }
      return "https://api2.gymkirchenfeld.ch/screen/";
    },
    screensFiltered() {
      return this.screens.filter(
        (el) => (el.content && el.content.trim()) || el.image
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.display = await this.apiList({
        resource: "screen/display",
      });
      this.overlay = !this.display.power;
      this.screens = await this.apiList({
        resource: "screen/screen",
      });
      this.$emit("loaded");
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
<style scoped>
img {
  max-width: 100%;
}
</style>
